<script>
/**
 * MFA interface for MFA group
 */
import { mapGetters } from "vuex";
import ProjectFlagService from "@/services/ProjectFlagService";
import UserService from "@/services/UserService";
import VFilters from "@/components/Search/VFilters";
import AppCfg from "@/mixins/AppCfg";
import MGConsts from "@/mixins/MGConsts";
import VFlag from "@/components/Planning/Flagging/VFlag";
import VStats from "./components/VStats";
import {
    FlagStatus,
    FlagTypes,
    FlagTypesDetailed,
    FlagTypesIcons,
    FlagSubtypes
} from "@/constants/Planning.const";
import VTimelineEditForm from "@/components/Forms/VTimelineEditForm";

export default {
    mixins: [AppCfg, MGConsts],
    components: { VFilters, VFlag, VStats, VTimelineEditForm },
    data() {
        return {
            loading: true,
            flags: [],
            meta: {},
            stats: {
                flags: 0,
                statuses: 0
            },
            users: [],
            showEditTimeline: false,
            timelineContext: "",
            timelineFlagContext: ""
        };
    },
    computed: {
        ...mapGetters("auth", ["getAclMap", "getAclRole"]),
        filters() {
            return [
                {
                    filter: "",
                    name: this.$t("flagging.f_type_all")
                },
                {
                    filter: "5",
                    name: this.$t("flagging.f_type_active")
                },
                {
                    filter: "1",
                    name: this.$t("flagging.f_type_awaiting")
                },
                {
                    filter: "2",
                    name: this.$t("flagging.f_type_denied")
                }
            ];
        },
        listFlags() {
            return FlagTypes;
        },
        flagTypes() {
            return this.$hash2select(FlagTypes);
        },
        flagDetails() {
            return FlagTypesDetailed;
        },
        flagIcons() {
            return FlagTypesIcons;
        },
        flagStatus() {
            return FlagStatus;
        },
        flagSubtypes() {
            return FlagSubtypes;
        }
    },
    async created() {
        this.$aclGuard(this.$ACL.CUSTOM_PLANNING_MFA);
        this.initSearch();
        await this.fetchUsers();
        await this.fetchData();
        this.$apiEnableWatcher();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                const { data } = await ProjectFlagService.GET(params);
                this.flags = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }
            this.fetchFlagStats();
        },
        async fetchFlagStats() {
            try {
                const { data } = await ProjectFlagService.GET_flagStats();
                this.stats = data.data;
            } catch (e) {}
        },
        async fetchUsers() {
            try {
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers exception", e);
            }
        },
        initSearch() {
            this.apiSearchFilter = {
                search: "",
                subtype: "",
                flag_type: "",
                user_id: "",
                status: "1"
            };
        },
        showGraph(project_id, flag_id) {
            this.timelineContext = project_id;
            this.timelineFlagContext = flag_id;
            this.showEditTimeline = true;
        },
        async flipStatus(flag_id, target_status) {
            try {
                await ProjectFlagService.PUT_changeStatus(
                    flag_id,
                    target_status
                );
                this.fetchData();
                this.$notify({
                    message: this.$t("flagging.notify_flag_status_changed"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });
            } catch (e) {
                console.log("fs", e);
            }
        }
    }
};
</script>

<template>
    <div class="v-project-flags-index">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-stats :stats="stats" view="flags" />
                </div>
                <div class="col-12">
                    <v-filters
                        :enable-filters="true"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :start-open="true"
                        :search-placeholder="$t('flagging.search_in_flags')"
                        @reset-search="initSearch"
                    >
                        <template slot="default">
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.subtype"
                                    :placeholder="$t('flagging.subtype')"
                                    type="multiselect"
                                    :multiple="false"
                                    :options="flagSubtypes"
                                />
                            </div>
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.flag_type"
                                    :placeholder="$t('flagging.flag_type')"
                                    type="multiselect"
                                    :multiple="false"
                                    :options="flagTypes"
                                />
                            </div>
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.status"
                                    :placeholder="$t('flagging.status')"
                                    type="multiselect"
                                    :multiple="false"
                                    :options="flagStatus"
                                />
                            </div>

                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.user_id"
                                    :placeholder="$t('flagging.user')"
                                    type="multiselect"
                                    :multiple="false"
                                    :options="users"
                                />
                            </div>
                        </template>
                    </v-filters>
                </div>
                <!-- Table with data -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="flags.length > 0">
                            <v-table
                                class="table table-mg table-sm table-striped"
                            >
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("flagging.tbl_hdr_date")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("flagging.tbl_hdr_project")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("flagging.tbl_hdr_user")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("flagging.tbl_hdr_flag_type")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("flagging.tbl_hdr_category")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("flagging.tbl_hdr_status")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("flagging.tbl_hdr_impact")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("flagging.tbl_hdr_mitigation")
                                        }}</v-th>
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="(f, idx) in flags" :key="f.id">
                                        <v-td
                                            >{{
                                                $d(new Date(f.created), "short")
                                            }}
                                            <div v-if="false" class="small text-muted">{{ f.id }}</div>
                                        </v-td>
                                        <v-td
                                            ><router-link
                                                :to="{
                                                    name: 'ProjectDisplay',
                                                    params: {
                                                        project_id: f.project.id
                                                    }
                                                }"
                                                >{{
                                                    f.project.title
                                                }}</router-link
                                            ></v-td
                                        >
                                        <v-td>{{ f.user.name }}</v-td>
                                        <v-td
                                            ><v-flag :flag="f.flag_type" />
                                            <span class="ml-1">{{
                                                flagSubtypes[f.subtype]
                                            }}</span>
                                            <div class="small">
                                                {{ flagDetails[f.flag_type] }}
                                            </div></v-td
                                        >
                                        <v-td>
                                            <template
                                                v-if="f.subtype == 'flag'"
                                                >{{
                                                    f.flag_category.name
                                                }}</template
                                            >
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td
                                            >{{ flagStatus[f.status] }}
                                            <template
                                                v-if="
                                                    f.status === 5 ||
                                                        f.status === 2 ||
                                                        f.status === 0
                                                "
                                            >
                                                <div class="text-bold">
                                                    {{
                                                        $t(
                                                            "flagging.by_USER_on_DATE",
                                                            {
                                                                USER:
                                                                    f.done_user
                                                                        .name,
                                                                DATE: $d(
                                                                    new Date(
                                                                        f.done_date
                                                                    ),
                                                                    "short"
                                                                )
                                                            }
                                                        )
                                                    }}:
                                                </div>
                                            </template>
                                        </v-td>
                                        <v-td>
                                            <v-newlines :text="f.description" />
                                        </v-td>
                                        <v-td>
                                            <v-newlines
                                                :text="f.description2"
                                            />
                                        </v-td>
                                        <v-td class="text-right">
                                            <base-dropdown
                                                :menu-on-right="true"
                                                :has-toggle="false"
                                            >
                                                <template slot="title-container"
                                                    ><base-button
                                                        class="mr-0"
                                                        type="primary"
                                                        size="sm"
                                                        ><i
                                                            class="far fa-hand-pointer"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "flagging.take_action"
                                                            )
                                                        }}</base-button
                                                    ></template
                                                >
                                                <div
                                                    class="dropdown-item"
                                                    @click="flipStatus(f.id, 5)"
                                                >
                                                    <i
                                                        class="far fa-fw fa-check"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "flagging.action_confirm"
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    class="dropdown-item text-danger"
                                                    @click="flipStatus(f.id, 2)"
                                                >
                                                    <i
                                                        class="far fa-fw fa-times"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "flagging.action_reject"
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    class="dropdown-item"
                                                    @click="flipStatus(f.id, 0)"
                                                >
                                                    <i
                                                        class="far fa-fw fa-smile"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "flagging.action_call_off"
                                                        )
                                                    }}
                                                </div>
                                            </base-dropdown>

                                            <base-button
                                                outline
                                                class="mt-2"
                                                v-if="
                                                    f.subtype ===
                                                        'timeline_change'
                                                "
                                                type="primary"
                                                size="sm"
                                                @click="
                                                    showGraph(
                                                        f.project_id,
                                                        f.id
                                                    )
                                                "
                                                ><i
                                                    class="far fa-chart-line"
                                                ></i>
                                                {{
                                                    $t("flagging.see_graph")
                                                }}</base-button
                                            >
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>

                            <div
                                class="paginator d-flex justify-content-center"
                            >
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    :total="meta.pagination.total"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <div class="alert alert-warning mx-4" v-else>
                            {{ $t("flagging.no_flags_found") }}
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-timeline-edit-form
                v-if="showEditTimeline"
                :project-id="timelineContext"
                :project-flag-id="timelineFlagContext"
                :force-ro="true"
                @close="showEditTimeline = false"
            />
        </portal>
    </div>
</template>
